.alert {
  padding: 16px;
  text-align: center;
  font-weight: bold;
}

.alert--success {
  color: white;
  background-color: #1b7d51;
}

.alert--error {
  color: white;
  background-color: #7d1b1b;
}
