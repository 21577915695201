.landing-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* hide sections while animating in */
}

.landing-hero::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(100vh - 400px);
  display: block;
  width: 100%;
  height: 400px;
  background: linear-gradient(
    to bottom,
    rgba(72, 72, 72, 0),
    rgba(72, 72, 72, 1)
  );
}

.landing-hero__image {
  content: '';
  position: absolute;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: url('../images/mall-color.jpg');
  background-size: cover;
  background-position: center center;
  transition: filter 3s ease-in-out;
  filter: grayscale();
}

.landing-hero__image--color {
  filter: none;
}

.landing-hero[aria-busy='true'] .landing-hero__animated {
  visibility: hidden;
}

.landing-hero__placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: linear-gradient(
      90deg,
      rgb(102, 102, 102) 10%,
      rgb(104, 104, 104) 10% 20%,
      rgb(69, 69, 69) 20% 30%,
      rgb(119, 119, 119) 30% 40%,
      rgb(192, 192, 192) 40% 50%,
      rgb(218, 218, 218) 50% 60%,
      rgb(146, 146, 146) 60% 70%,
      rgb(113, 113, 113) 70% 80%,
      rgb(97, 97, 97) 80% 90%,
      rgb(88, 88, 88) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(107, 107, 107) 10%,
      rgb(113, 113, 113) 10% 20%,
      rgb(85, 85, 85) 20% 30%,
      rgb(116, 116, 116) 30% 40%,
      rgb(181, 181, 181) 40% 50%,
      rgb(219, 219, 219) 50% 60%,
      rgb(138, 138, 138) 60% 70%,
      rgb(105, 105, 105) 70% 80%,
      rgb(86, 86, 86) 80% 90%,
      rgb(81, 81, 81) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(99, 99, 99) 10%,
      rgb(99, 99, 99) 10% 20%,
      rgb(130, 130, 130) 20% 30%,
      rgb(127, 127, 127) 30% 40%,
      rgb(135, 135, 135) 40% 50%,
      rgb(170, 170, 170) 50% 60%,
      rgb(126, 126, 126) 60% 70%,
      rgb(89, 89, 89) 70% 80%,
      rgb(77, 77, 77) 80% 90%,
      rgb(104, 104, 104) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(89, 89, 89) 10%,
      rgb(83, 83, 83) 10% 20%,
      rgb(100, 100, 100) 20% 30%,
      rgb(93, 93, 93) 30% 40%,
      rgb(98, 98, 98) 40% 50%,
      rgb(148, 148, 148) 50% 60%,
      rgb(143, 143, 143) 60% 70%,
      rgb(73, 73, 73) 70% 80%,
      rgb(80, 80, 80) 80% 90%,
      rgb(97, 97, 97) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(74, 74, 74) 10%,
      rgb(86, 86, 86) 10% 20%,
      rgb(116, 116, 116) 20% 30%,
      rgb(59, 59, 59) 30% 40%,
      rgb(68, 68, 68) 40% 50%,
      rgb(153, 153, 153) 50% 60%,
      rgb(176, 176, 176) 60% 70%,
      rgb(93, 93, 93) 70% 80%,
      rgb(77, 77, 77) 80% 90%,
      rgb(60, 60, 60) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(92, 92, 92) 10%,
      rgb(101, 101, 101) 10% 20%,
      rgb(110, 110, 110) 20% 30%,
      rgb(55, 55, 55) 30% 40%,
      rgb(32, 32, 32) 40% 50%,
      rgb(174, 174, 174) 50% 60%,
      rgb(191, 191, 191) 60% 70%,
      rgb(124, 124, 124) 70% 80%,
      rgb(115, 115, 115) 80% 90%,
      rgb(95, 95, 95) 90% 100%
    ),
    linear-gradient(
      90deg,
      rgb(77, 77, 77) 10%,
      rgb(79, 79, 79) 10% 20%,
      rgb(86, 86, 86) 20% 30%,
      rgb(69, 69, 69) 30% 40%,
      rgb(24, 24, 24) 40% 50%,
      rgb(130, 130, 130) 50% 60%,
      rgb(151, 151, 151) 60% 70%,
      rgb(119, 119, 119) 70% 80%,
      rgb(113, 113, 113) 80% 90%,
      rgb(99, 99, 99) 90% 100%
    );
  background-position: 0 0, 0 16.666666666666664%, 0 33.33333333333333%, 0 50%,
    0 66.66666666666666%, 0 83.33333333333334%, 0 100%;
  background-size: 100% 14.285714285714286%;
  background-repeat: no-repeat;
  filter: blur(24px);
  transform: scale(1.2);
}

.landing-hero__spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.landing-sections {
  display: flex;
  align-items: center;
  height: 150px;
  position: absolute;
  z-index: 2;
  bottom: 20vh;
  left: 0;
  right: 0;
}

@media (min-width: 832px) {
  .landing-sections {
    margin-top: 180px;
    height: 50vh;
    position: relative;
    bottom: 0;
  }
}

.landing-section {
  display: flex;
  align-items: center;
  max-height: 100%;
  width: 50vw;
  padding: 48px 32px;
  background: linear-gradient(
    to right,
    rgba(48, 48, 48, 0.95),
    rgba(48, 48, 48, 0.01)
  );
}

@media (min-width: 832px) {
  .landing-section {
    width: 30vw;
  }
}

@media (min-width: 1200px) {
  .landing-section {
    padding: 48px 72px;
  }
}

.landing-section--right {
  margin-left: auto;
  text-align: right;
  background: linear-gradient(
    to right,
    rgba(48, 48, 48, 0.01),
    rgba(48, 48, 48, 0.95)
  );
}

.landing-title {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 65px;
  letter-spacing: 0.08em;
  font-size: 24px;
  font-weight: bold;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

@media (min-width: 832px) {
  .landing-title {
    font-size: 32px;
  }
}

@media (min-width: 1250px) {
  .landing-title {
    font-size: 42px;
  }
}

@media (min-width: 1520px) {
  .landing-title {
    font-size: 56px;
  }
}

.landing-nav {
  width: 100%;
  padding-top: 500px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  background-image: url('../images/walking.jpg');
  background-size: cover;
  background-position: center center;
}

.landing-nav::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to top,
    rgba(72, 72, 72, 0.5),
    rgba(72, 72, 72, 1)
  );
}

.landing-nav__title {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 16px;
  padding-right: 16px;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
}

@media (min-width: 832px) {
  .landing-nav__title {
    font-size: 48px;
  }
}

.landing-nav__links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
}

.landing-nav__link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 64px;
  width: 100%;
  height: 72px;
  color: white;
  border: 3px solid white;
  transition: padding 0.3s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
}

@media (min-width: 832px) {
  .landing-nav__link {
    width: calc(50% - 32px);
    height: 100px;
    font-size: 28px;
  }
}

.landing-nav__link::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  transition: all 0.3s ease-in-out;
}

.landing-nav__link:hover,
.landing-nav__link:focus {
  padding: 0.75rem 2.25rem;
}

.landing-nav__link:hover::before {
  top: 0;
  left: 0;
}
