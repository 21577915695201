.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.8);
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

@media (min-width: 832px) {
  .logo {
    width: 180px;
    height: 90px;
    font-size: 40px;
  }
}

.logo--filled {
  background-color: #222;
  border-color: white;
  color: white;
}

.logo-centered-container {
  padding-top: 32px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 832px) {
  .logo-centered-container {
    padding-top: 85px;
  }
}
