.masonry {
  column-count: 1;
  column-gap: 1em;
}

.masonry__item {
  background-color: #222;
  display: inline-block;
  margin-bottom: 1em;
  width: 100%;
  overflow: hidden;
}

.masonry__item img {
  transition: transform 500ms ease-in-out;
}

.masonry__item img:hover {
  transform: scale(1.2);
}

@media (min-width: 832px) {
  .masonry {
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .masonry {
    column-count: 3;
  }
}
