.input {
  display: block;
  max-width: 100%;
  max-height: 200px;
  width: 100%;
  padding: 0.75em 1em;
  border: 2px solid rgba(255, 255, 255, 0.2);
  font-size: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  transition: border-color 200ms ease-in-out, background-color 200ms ease-in-out;
}

.input:hover,
.input:focus {
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.75);
  outline: none;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.85);
}

.input-group {
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .input-group {
    flex-direction: row;
  }
}

.input-group > input:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: 600px) {
  .input-group > input:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.input__help-text {
  padding-top: 4px;
  color: rgba(255, 255, 255, 0.75);
}
