.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #222;
}

.header > :not(:last-child) {
  margin-bottom: 32px;
}

@media (min-width: 1200px) {
  .header {
    flex-direction: row;
    padding-left: 64px;
    padding-right: 64px;
  }
}
