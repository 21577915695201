.radio-toolbar {
  display: flex;
  justify-content: center;
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  padding: 0.75em 2em;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.2);
  font-size: 18px;
  cursor: pointer;
  transition: border-color 200ms ease-in-out, background-color 200ms ease-in-out;
}

.radio-toolbar label:not(:last-child) {
  margin-right: 8px;
}

.radio-toolbar input[type='radio']:hover + label {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.75);
}

.radio-toolbar input[type='radio']:checked + label {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.75);
}

.radio-toolbar input[type='radio']:checked + label {
  border-color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.75);
}
