.modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal__content {
  max-width: 100%;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  font-size: 20px;
  color: white;
  background: #222;
}

.modal__content a {
  color: white;
}

a.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  line-height: 48px;
  color: #aaa;
  font-size: 80%;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.modal__close:hover,
.modal__close:focus {
  color: white;
}

.modal__links-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.modal__links-list-item:not(:last-child) {
  margin-bottom: 16px;
}

.modal__links-list-item > a {
  display: inline-flex;
  align-items: center;
  padding: 4px;
}

.modal__links-list-item svg {
  margin-right: 8px;
}
