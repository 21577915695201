.footer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 64px 32px;
  background-color: #222;
  border-top: 4px solid rgba(255, 255, 255, 0.5);
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
}

@media (min-width: 1200px) {
  .footer__content {
    flex-direction: row;
  }
}

.footer__item {
  font-size: 32px;
}

.footer__item a {
  color: white;
}

.footer__item--left {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}

@media (min-width: 1200px) {
  .footer__item--left {
    justify-content: flex-end;
    width: calc(50% - 180px);
    margin-bottom: 0;
  }
}

.footer__item--center {
  margin-left: 64px;
  margin-right: 64px;
  max-width: 280px;
}

.footer__item--center img {
  border-radius: 50%;
  opacity: 0.85;
  filter: grayscale(100%);
  transition: opacity ease-in-out 200ms, filter ease-in-out 200ms;
}

.footer__item--center img:hover {
  opacity: 1;
  filter: none;
}

.footer__item--right {
  width: 100%;
  margin-top: 32px;
}

@media (min-width: 1200px) {
  .footer__item--right {
    width: calc(50% - 180px);
    margin-top: 0;
  }
}

.footer__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .footer__link {
    justify-content: flex-start;
  }
}

.footer__link-icon {
  display: block;
  margin-right: 4px;
  color: rgba(255, 255, 255, 0.5);
  transition: color 200ms ease-in-out;
}

.footer__link:hover .footer__link-icon,
.footer__link:focus .footer__link-icon {
  color: white;
}

.footer__link-hashtag {
  padding-right: 2px;
}

.footer__link-text {
  display: block;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
