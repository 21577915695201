.content-with-image {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-left: 32px;
  margin-right: 32px;
}

.content-with-image__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 32px;
  background-color: #222;
}

@media (min-width: 832px) {
  .content-with-image__container {
    flex-direction: row;
    padding: 64px;
  }
}

.content-with-image__image {
  margin-top: -32px;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 32px;
}

@media (min-width: 832px) {
  .content-with-image__image {
    margin: 0;
    max-width: 400px;
  }
}

.content-with-image__content {
  text-align: center;
}

.content-with-image__content--left-aligned {
  text-align: left;
}

@media (min-width: 832px) {
  .content-with-image__content {
    padding-left: 64px;
  }
}

.content-with-image__link {
  color: white;
}

.content-with-image__content h2 {
  margin-bottom: 32px;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
}

.content-with-image__content p {
  margin-bottom: 64px;
  max-width: 556px;
  font-size: 20px;
  line-height: 2;
}
