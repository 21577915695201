.nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -32px;
  width: 100%;
  position: relative;
  z-index: 1;
}

@media (min-width: 1200px) {
  .nav {
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;
  }
}

.nav__item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 14px 16px;
  max-height: 60px;
  color: white;
  background-color: #222;
  border: 3px solid white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
}

.nav__item:not(:last-child) {
  margin-bottom: 32px;
  margin-right: 32px;
}

@media (min-width: 832px) {
  .nav__item {
    padding: 22px 24px;
    max-height: 80px;
    font-size: 18px;
  }
}

.nav__item--active {
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0.8);
}

.nav__item::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: white;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  transition: all 0.3s ease-in-out;
}

.nav__item:hover::before,
.nav__item:focus::before,
.nav__item--active::before {
  top: 0;
  left: 0;
}
