.ellipsis-separator {
  display: block;
  text-align: center;
  overflow: visible;
  border: none;
}

.ellipsis-separator::before {
  content: '...';
  display: inline-block;
  position: relative;
  top: -8px;
  font-size: 42px;
  letter-spacing: 16px;
}
