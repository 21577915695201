.spacing--0 {
  margin-bottom: 0 !important;
}

.spacing--1 {
  margin-bottom: 8px !important;
}

.spacing--2 {
  margin-bottom: 16px !important;
}

.spacing--3 {
  margin-bottom: 24px !important;
}

.spacing--4 {
  margin-bottom: 32px !important;
}

.spacing--5 {
  margin-bottom: 48px !important;
}

.spacing--6 {
  margin-bottom: 64px !important;
}
