.title {
  font-size: 56px;
  font-weight: bold;
  font-variant: small-caps;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color: white;
}

.title--small {
  font-size: 40px;
}

.title--center {
  text-align: center;
}
