.spinner,
.spinner * {
  box-sizing: border-box;
}

.spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.spinner__circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.spinner__circle--first {
  border-top-color: currentColor;
  animation: spinner-animation 1s infinite;
}

.spinner__circle--second {
  border-bottom-color: currentColor;
  animation: spinner-animation 1s infinite alternate;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
