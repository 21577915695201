.button {
  display: inline-block;
  position: relative;
  z-index: 0;
  padding: 1rem 2rem 0.5rem 2.5rem;
  color: black;
  border: 3px solid #484848;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  background-color: transparent;
  transition: padding 0.3s ease-in-out;
  cursor: pointer;
}

.button::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: -1;
  height: 100%;
  width: 100%;
  background-color: white;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
  transition: all 0.3s ease-in-out;
}

.button:hover,
.button:focus {
  padding: 0.75rem 2.25rem;
}

.button:hover::before,
.button:hover::before {
  top: 0;
  left: 0;
}
